import MainBanner from './components/MainBanner'
import BlockOne from './components/BlockOne'
import BlockTwo from './components/BlockTwo'
import Slider from './components/Slider'
import FormContact from './components/FormContact'

function App() {
  return (
    <div className="App">
      <header className="md:container md:mx-auto text-center px-3 py-5">
        <img src={'/images/Logo.png'} className="max-w-[300px] mx-auto" alt="Logo impacta" />
      </header>
      <main className="md:container md:mx-auto min-h-screen">
        <MainBanner />
        <BlockOne />
        <BlockTwo />
        <Slider />
        <FormContact />
      </main>
      <footer className="md:container md:mx-auto text-center p-10 py-20 bg-blueDark rounded-t-3xl text-white mt-40">
        <div className="flex flex-col items-center md:items-end">
          <div className="w-[100px] h-3 bg-white mb-5"></div>
          <img src={'/images/LogoWhite.png'} className="max-w-[300px]" alt="Logo impacta Footer" />
          <p className="mt-5">Impacta 2022. Todos los derechos reservados</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
