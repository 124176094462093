function Component() {
    return (
        <div className="px-5 my-10 mb-20">
            <div className="bg-bannerTop bg-cover bg-top bg-no-repeat mt-10 mb-20 min-h-[500px]">
                <div className="text-white caption text-center flex flex-col items-center justify-center min-h-[500px] decoration-4 shadow-text-lg">
                    <h1 className="text-6xl font-bold text-white">
                        Tecnología y educación
                    </h1>
                    <h2 className="text-3xl font-bold text-white">Un propósito que cambia vidas</h2>
                </div>
            </div>
        </div>
    );
}

export default Component;