// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBD13BLm-sHM_baBceVNp_2BT3tlkK-hsQ",
  authDomain: "smartrent-landing-pages.firebaseapp.com",
  projectId: "smartrent-landing-pages",
  storageBucket: "smartrent-landing-pages.appspot.com",
  messagingSenderId: "931631441746",
  appId: "1:931631441746:web:c5f782f35a63dbe1e87c8b",
  measurementId: "G-GWM04HHVP8"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default getFirestore()