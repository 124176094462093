import React, { useState, useEffect } from 'react';


// GODDADY:
// HOST: impactacolombia.co
// TXT = google-site-verification=ff9NdlML4DEimaHTDZcC9OGseY1XJzSipbZ3nVII3N8
// A: 199.36.158.100

// Form Libraries
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";

// Firebase
import { collection, setDoc, doc, Timestamp } from 'firebase/firestore'
import db from '../services/firebase'

const SITE_NAME = 'ImpactaFormContactV01'
const SITE_KEY = '6LfkL_MgAAAAAEnCnEC6ff2JDmxDLE6OCIUkJwm9'
// const SITE_KEY_INV = '6LdM1fMgAAAAAOHryBp4yO6VJ2u2qQiZEMZMqurF'

const programs = [
    "Ciencias Políticas y Relaciones Internacionales",
    "Ingeniería Industrial",
    "Ingeniería de sistemas",
    "Administración de Negocios Internacionales",
    "Publicidad y Relaciones Públicas",
    "Postgrados, maestrias y doctorados",
    "Otro"
]

const formSchema = Yup.object({
    fullname: Yup.string().required('Campo requerido'),
    email: Yup
        .string()
        .email('Email inválido')
        .required('Email necesario'),
    phone: Yup.string()
        .required('Requerido')
        .matches(/^[0-9]+$/, `Debe ser un número celular válido`)
        .min(10, `Debe ser un número celular válido`)
        .max(10, `Debe ser un número celular válido`),
    preference: Yup.string(),
    recaptcha: Yup.string().nullable().required('¿Eres humano cierto?')
})

const FormContact = ({setAlert}) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let mount = true
        if (mount) {
            loadRecaptcha()
        }
        return () => {
            mount = false
        }
    }, [])

    function loadRecaptcha() {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    function registerValues(values) {
        setLoading(true)
        // New Reference
        const ref = doc(collection(db, SITE_NAME))
        // Save Doc
        return setDoc(ref, Object.assign({}, values, { created: Timestamp.fromDate(new Date())}))
        .then((response) => {
            setLoading(false)
            setAlert({
                text: 'Tu información ha sido enviada correctamente. Estaremos en contacto cuanto antes.',
                error: false,
            })
        })
        .catch((error) => {
            setLoading(false)
            setAlert({
                text: 'Lo sentimos. No hemos recibido tu información. Revisa los datos e intenta nuevamente.',
                error: true,
            })
        })
    }

    return (
        <Formik
          initialValues = {{
            _type: 'impacta',
            fullname: '',
            email: '',
            phone: '',
            preference: 'Otro',
            benefits: [],
            recaptcha: "",
          }}
          validationSchema = { formSchema }
          onSubmit={(values, actions) => {
            registerValues(values)
            .then(() => {
                actions.resetForm();
                console.log('Executed')
            })
          }}
        >
          {( errors, values, touched, setFieldValue ) => (
            <Form>
                {/* Fullname */}
                <div className="group flex flex-col text-left my-3">
                    <label className="label-form" htmlFor="fullname">Nombre completo</label>
                    <Field name="fullname">
                        {({
                            field,
                            form: { touched, errors },
                            meta,
                        }) => (
                        <div>
                            <input type="text" placeholder="Nombre completo" disabled={loading} className="form-input" {...field} />
                            {errors.fullname && touched.fullname && (
                                <p className="alert-form">{errors.fullname}</p>
                            )}
                        </div>
                        )}
                    </Field>
                </div>
                {/* Email */}
                <div className="group flex flex-col text-left my-3">
                    <label className="label-form" htmlFor="email">Email</label>
                    <Field name="email">
                        {({
                            field,
                            form: { touched, errors },
                            meta,
                        }) => (
                        <div>
                            <input type="email" placeholder="Email" disabled={loading} className="form-input" {...field} />
                            {errors.email && touched.email && (
                                <p className="alert-form">{errors.email}</p>
                            )}
                        </div>
                        )}
                    </Field>
                </div>
                {/* Cellphone */}
                <div className="group flex flex-col text-left my-3">
                    <label className="label-form" htmlFor="phone">Celular</label>
                    <Field name="phone">
                        {({
                            field,
                            form: { touched, errors },
                            meta,
                        }) => (
                        <div>
                            <input type="text" placeholder="Celular" disabled={loading} className="form-input" {...field} />
                            {errors.phone && touched.phone && (
                                <p className="alert-form">{errors.phone}</p>
                            )}
                        </div>
                        )}
                    </Field>
                </div>
                {/* Program */}
                <div className="group flex flex-col text-left my-3">
                    <label className="label-form" htmlFor="preference">Programa de interés</label>
                    <Field as="select" name="preference" disabled={loading} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-3xl leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        {programs.map((item, index) => (
                            <option key={index} value={item} label={item} />
                        ))}
                    </Field>
                </div>
                {/* Benefits */}
                <h3 className="my-3 text-xl font-bold shadow-text">¿Cómo quisieras recibir este beneficio?</h3>
                <div className="grid grid-cols-2 gap-4">
                    <label className="checkbox-form">
                        <span className="text-sm mr-2">
                            Educación
                        </span>
                        <Field type="checkbox" name="benefits" value="Educación" />
                    </label>
                    <label className="checkbox-form">
                        <span className="text-sm mr-2">
                            Tecnología
                        </span>
                        <Field type="checkbox" name="benefits" value="Tecnología" />
                    </label>
                    <label className="checkbox-form">
                        <span className="text-sm mr-2">
                            Internet
                        </span>
                        <Field type="checkbox" name="benefits" value="Internet" />
                    </label>
                    <label className="checkbox-form">
                        <span className="text-sm mr-2">
                            Seguro
                        </span>
                        <Field type="checkbox" name="benefits" value="Seguro" />
                    </label>
                </div>
                {/* reCaptcha */}
                <div className="mt-5 flex items-center">
                    <Field name="recaptcha">
                        {({
                            field,
                            form: { touched, errors, setFieldValue },
                            meta,
                        }) => (
                        <div className="mx-auto">
                            <ReCAPTCHA
                                sitekey={SITE_KEY}
                                onChange={(event) => {
                                    setFieldValue("recaptcha", event)
                                }}
                            />
                            {errors.recaptcha && touched.recaptcha && (
                                <p className="alert-form">{errors.recaptcha}</p>
                            )}
                        </div>
                        )}
                    </Field>
                </div>
                {/* Submit button */}
                <button
                    type="submit"
                    className={`submit btn bg-blue h-[48px] w-[200px] font-bold uppercase rounded-3xl mt-5 ${loading ? 'loading' : null}`}
                    disabled={loading}
                >
                    {loading ? 'Enviando' : 'Enviar' }
                </button>
            </Form>
          )}
        </Formik>
    )
}

function Component() {
    const [alert, setAlert] = useState({})

    useEffect(() => {
        let mount = true
        if (mount && alert?.text) {
            setTimeout(() => {
                setAlert({})
            }, 5000)
        }
        return () => {
            mount = false
        }
    }, [alert])

    return (
        <div className="px-5 max-w-[1200px] mx-auto">
            <div className="bg-form2 bg-contain bg-no-repeat sm:bg-left bg-top relative">
                {alert && alert?.text && !alert.error && (
                    <div className="alert alert-success shadow-lg absolute w-full">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            <span>{alert.text}</span>
                        </div>
                    </div>
                )}
                {alert && alert?.text && alert.error && (
                    <div className="alert alert-warning shadow-lg absolute w-full">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                            <span>{alert.text}</span>
                        </div>
                    </div>
                )}
                <div className="bg-gradient-to-l from-blueLight/80 to-blue/80 min-h-[400px] my-10 rounded-3xl flex items-center justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 items-center justify-center w-full py-10">
                        <div className="w-12/6 text-center text-white px-5">
                            <div className="max-w-[400px] mx-auto shadow-text">
                                <h2 className="text-3xl font-bold">¿Quieres formar parte de IMPACTA?</h2>
                                <p className="text-xl mt-3">Porque juntos podemos lograr cosas extraordinarias</p>
                            </div>
                        </div>
                        <div className="w-12/6 text-center text-white px-5 max-w-[400px] mx-auto">
                            <h3 className="text-3xl font-bold shadow-text">Regístrate</h3>
                            <FormContact setAlert={setAlert} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Component;