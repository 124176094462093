const Box = ({ title, text, image, bg, classW }) => (
    <div className={`${bg} text-center p-5 text-white ${classW} pb-10`}>
        <img src={`/images/${image}`} className="max-w-[200px] w-full mx-auto" alt={title} />
        <h3 className="text-2xl font-bold">{title}</h3>
        <p className="mt-5">{text}</p>
    </div>
)

function Component() {
    return (
        <div className="px-5 my-10 max-w-[1200px] mx-auto">
            <h2 className="text-4xl font-regular text-gray-600 mb-10 text-center max-w-[800px] mx-auto">
                Llegó la oportunidad de realizar tus sueños, queremos que seas parte del cambio al igual que toda tu familia, todo a través de:
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-0">
                <Box
                    title="Innovación"
                    text="Te entregamos un equipo totalmente nuevo para llevar a cabo tu estudio"
                    image="Icon7.png"
                    bg="bg-blue"
                    classW="order-1"
                />
                <Box
                    title="Educación"
                    text="5 programas de pregrado. Programas de doble titulación. 120 Especializaciones. 99 Maestrías"
                    image="Icon4.png"
                    bg="bg-blueDark"
                    classW="order-2"
                />
                <Box
                    title="Conectividad"
                    text="Conectividad 4G con acceso ilimitado a la plataforma de estudios, así como a el material didáctico"
                    image="Icon5.png"
                    bg="bg-blue"
                    classW="order-4 sm:order-3"
                />
                <Box
                    title="Seguridad"
                    text="Un seguro robusto durante todo el periodo de estudio que te respalda en caso de incovenientes"
                    image="Icon6.png"
                    bg="bg-blueDark"
                    classW="order-3 sm:order-4"
                />
            </div>
        </div>
    );
}

export default Component;