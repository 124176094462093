const Box = ({ title, text, image = null, bg, classW }) => (
    <div className={`${bg} text-center p-3 text-gray-600 ${classW} pb-10`}>
        {image && <img src={`/images/${image}`} className="max-w-[150px] w-full mx-auto" alt={title} />}
        <h3 className="text-xl font-bold">{title}</h3>
        <p className="text-xl mt-0">{text}</p>
    </div>
)

const Box2 = ({ title, text = null, bg, classW }) => (
    <div className={`${bg} text-center flex items-center p-5 py-10 text-white ${classW} h-full`}>
        <div className="decoration-4 shadow-text">
            <h3 className="text-2xl font-bold">{title}</h3>
        </div>
        {text && <p className="mt-5">{text}</p>}
    </div>
)

function Component() {
    return (
        <div className="px-5 my-10 mb-20 max-w-[1200px] mx-auto">
            <h2 className="text-4xl font-regular text-gray-600 mb-10 text-center max-w-[800px] mx-auto">
                Tienes la oportunidad de estudiar en Colombia y otros países del mundo
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-5 gap-2">
                <Box
                    title="5 programas"
                    text="con doble titulación"
                    image="Icon9.png"
                    bg="bg-white"
                />
                <Box
                    title="9 Licenciaturas"
                    text="Modalidad virtual en México"
                    image="Icon8.png"
                    bg="bg-white"
                />
                <Box
                    title="120 programas"
                    text="de especialización"
                    image="Icon1.png"
                    bg="bg-white"
                />
                <Box
                    title="99 programas"
                    text="de maestriás"
                    image="Icon2.png"
                    bg="bg-white"
                />
                <Box
                    title="8 programas"
                    text="de doctorado"
                    image="Icon3.png"
                    bg="bg-white"
                />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-5 gap-0 mt-5">
                <div className="bg-i1 bg-contain">
                    <Box2
                        title="Ciencias Políticas y Relaciones Internacionales"
                        text={null}
                        image='i1'
                        bg="bg-blueLight/70"
                        classW="order-1"
                    />
                </div>
                <div className="bg-i4 bg-contain">
                    <Box2
                        title="Ingeniería Industrial"
                        text={null}
                        image='i4'
                        bg="bg-blue/70"
                        classW="order-2"
                    />
                </div>
                <div className="bg-i5 bg-contain">
                    <Box2
                        title="Ingeniería de sistemas"
                        text={null}
                        image='i5'
                        bg="bg-blueLight/70"
                        classW="order-4 sm:order-3"
                    />
                </div>
                <div className="bg-i2 bg-contain">
                    <Box2
                        title="Administración de Negocios Internacionales"
                        text={null}
                        image='i2'
                        bg="bg-blue/70"
                        classW="order-3 sm:order-4"
                    />
                </div>
                <div className="bg-i3 bg-contain">
                    <Box2
                        title="Publicidad y Relaciones Públicas"
                        text={null}
                        image='i3'
                        bg="bg-blueLight/70"
                        classW="order-5"
                    />
                </div>
            </div>

        </div>
    );
}

export default Component;