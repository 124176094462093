// CAROUSEL
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

function Component() {
    return (
        <div className="my-20">
            <h2 className="text-4xl font-bold text-gray-600 mb-10 text-center max-w-[900px] mx-auto">
                Las marcas que nos ayudan a cumplir tus sueños
            </h2>
            <div className="p-10">
                <Carousel responsive={responsive}>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                        <div key={item} className="px-10">
                            <div className="carousel-item relative">
                                <img src={`/images/aliados/b${item}.png`} className="max-h-[100px] w-auto mx-auto" alt={index} />
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default Component;